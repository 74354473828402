<div class="holder">
  <div class="breadcrumbs">
    <span (click)="navigate(-1)" (keyup.enter)="navigate(-1)" tabindex="0" role="button"
          class="link">Sparbanken Syd<span class="thin"> |  </span> </span>
    <ng-container *ngFor="let link of elements; let idx = index; let lst = last; let fst = first">
      <span *ngIf="!fst" class="last"> /  </span>
      <span (click)="navigate(idx)" (keyup.enter)="navigate(idx)" tabindex="0" role="button" *ngIf="!lst"
            class="link">{{link}}</span>
      <span *ngIf="lst" class="last">{{link}}</span>
    </ng-container>
  </div>
</div>
