import {Inject, Injectable, signal} from '@angular/core'
import {Router} from '@angular/router'
import {HelperService, SingleSignOnService, TokenPayload} from '@sparbanken-syd/sparbanken-syd-bankid'
import {catchError, EMPTY, merge, Observable, of, switchMap} from 'rxjs'
import {environment} from '../../environments/environment'
import {LOCAL_STORAGE} from '../application/localstorage.provider'

const ACCESS_TOKEN_NAME = 'blanco-admin-fe-at'

/**
 * We export this so that we can use it in test
 */
export const ADMIN_ROLE_NAME = 'blancoAdmin'

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  /**
   * The access token, primarily needed for the auth interceptor
   */
  public accessToken$ = signal<string | null>(null)

  /**
   * This is for the "admin", you can add additional
   */
  public isAdmin$ = signal<boolean>(false)

  constructor(
    @Inject(LOCAL_STORAGE) private injectedLocalStorage: Storage,
    private ssoService: SingleSignOnService,
    private router: Router
  ) {
  }

  /**
   * This is called from the app module bootstrapper only. So
   * it will happen once and once only.
   */
  public bootstrap(): Observable<boolean> {
    // Start by initiating SSO, it will take a while.
    // Merge will take both, Concat will take them in order
    return merge(
      this.sso(),
      of(this.injectedLocalStorage.getItem(ACCESS_TOKEN_NAME))
    )
      .pipe(
        switchMap((value: string | null) => {
          return this.setToken(value)
        })
      )
  }

  /**
   * Called whenever we have token, a token can come from three valid source
   *
   * 1. Localstorage
   * 2. From the SSO service
   * 3. From BankID login.
   *
   * We do not care, and we validate and set whatever we get.
   */
  public setToken(token: string | null): Observable<boolean> {
    const payLoad: TokenPayload | null = HelperService.GetTokenPayload(token)
    if (payLoad) {

        this.accessToken$.set(token)
        this.isAdmin$.set(payLoad.roles.includes(ADMIN_ROLE_NAME))
        // String assertion needed since only payload is verified
        // but we know that it is a string...
        this.injectedLocalStorage.setItem(ACCESS_TOKEN_NAME, token as string)

    }
    return of(true)
  }


  /**
   * Call the SSO service, if we get something we return
   * that. Otherwise, nothing. Must be anonymous since we
   * call it from merge
   */
  public sso = (): Observable<string> | never => {
    return this.ssoService.getToken(environment.authServiceUrl, environment.domain)
      .pipe(
        catchError(() => {
          // We MUST log out if the SSO service says we are logged out!
          this.logout()
          return EMPTY
        })
      )
  }

  /**
   * Reset all admin values, including SSO and
   * go back to log-in.
   */
  public logout(): void {
    // This can potentially be a long list of resets...
    this.accessToken$.set(null)
    this.isAdmin$.set(false)
    this.injectedLocalStorage.removeItem(ACCESS_TOKEN_NAME)
    // Blindly just log out from SSO, ignore any errors
    this.ssoService.deleteToken(environment.authServiceUrl).subscribe()
    // Go to log-in
    this.router.navigate(['/']).then()
  }
}
